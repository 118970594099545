import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { AxiosResponse } from "axios"
import { ISister } from 'app/shared/model/sister.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export const getSisters = async (): Promise<AxiosResponse<ISister[]>> => {
  try {
    const sisters: AxiosResponse<ISister[]> = await axios.get(
      "api/sisters/today"
    )
    return sisters
  } catch (error) {
    throw new Error(error)
  }
}

export const Prayerlist: React.FC = () => {

  const [sisterList, setSisterList] = useState<ISister[]>([])

  useEffect(() => {
    axios.get('api/sisters/today')
      .then(sisterListResponse => {
        setSisterList(sisterListResponse.data)
      })
      .catch(err => alert(err))
  }, [])

  return (
    <div>
      <h2 id="sister-heading">
        {<TextFormat type="date" value={Date.now()} format={'MMMM Do'} />}
      </h2>
      <div className="table-responsive">
        {sisterList && sisterList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="sistersOfProvidenceNecrologyApp.sister.title">Title</Translate>
                </th>
                <th>
                  <Translate contentKey="sistersOfProvidenceNecrologyApp.sister.firstName">First Name</Translate>{' '}
                </th>
                <th>
                  <Translate contentKey="sistersOfProvidenceNecrologyApp.sister.lastName">Last Name</Translate>{' '}
                </th>
                <th>
                  Deceased Year
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {sisterList.map((sister, i) => (
                <tr key={`entity-${i}`}>
                  <td>{sister.title}</td>
                  <td>{sister.firstName}</td>
                  <td>{sister.lastName}</td>
                  <td>
                    {sister.deceasedDate ? <TextFormat type="date" value={sister.deceasedDate} format={'YYYY'} /> : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (

            <div className="alert alert-warning">
              <Translate contentKey="sistersOfProvidenceNecrologyApp.sister.home.notFound">No Sisters found</Translate>
            </div>

        )}
      </div>
    </div>
  );
};

