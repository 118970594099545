import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './sister.reducer';
import { ISister } from 'app/shared/model/sister.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ISisterDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const SisterDetail = (props: ISisterDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { sisterEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="sistersOfProvidenceNecrologyApp.sister.detail.title">Sister</Translate> [<b>{sisterEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="title">
              <Translate contentKey="sistersOfProvidenceNecrologyApp.sister.title">Title</Translate>
            </span>
          </dt>
          <dd>{sisterEntity.title}</dd>
          <dt>
            <span id="firstName">
              <Translate contentKey="sistersOfProvidenceNecrologyApp.sister.firstName">First Name</Translate>
            </span>
          </dt>
          <dd>{sisterEntity.firstName}</dd>
          <dt>
            <span id="lastName">
              <Translate contentKey="sistersOfProvidenceNecrologyApp.sister.lastName">Last Name</Translate>
            </span>
          </dt>
          <dd>{sisterEntity.lastName}</dd>
          <dt>
            <span id="deceasedDate">
              <Translate contentKey="sistersOfProvidenceNecrologyApp.sister.deceasedDate">Deceased Date</Translate>
            </span>
          </dt>
          <dd>
            {sisterEntity.deceasedDate ? <TextFormat value={sisterEntity.deceasedDate} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="link">
              <Translate contentKey="sistersOfProvidenceNecrologyApp.sister.link">Link</Translate>
            </span>
          </dt>
          <dd>{sisterEntity.link}</dd>
        </dl>
        <Button tag={Link} to="/sister" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/sister/${sisterEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ sister }: IRootState) => ({
  sisterEntity: sister.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SisterDetail);
