import { Moment } from 'moment';

export interface ISister {
  id?: number;
  title?: string;
  firstName?: string;
  lastName?: string;
  deceasedDate?: string;
  link?: string;
}

export const defaultValue: Readonly<ISister> = {};
